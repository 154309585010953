<template>
    <div class="modal fade show" id="wallModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-modal="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form id="wallForm" class="kt-form" novalidate="novalidate" @submit.prevent="onSubmit">
                    <div class="modal-header">
                        <h5 v-if="isCreation" class="modal-title">{{ $t("wall_wallCreation") }}</h5>
                        <h5 v-else class="modal-title">{{ $t("wall_wallUpdate") }}</h5>
                        <button type="button" @click="cancelWall" class="close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group validated">
                            <label>{{ $t("wall_wallName") }} *</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="la la-pencil"></i></span>
                                </div>
                                <input id="wallNameInput" type="text" class="form-control" :placeholder="$t('wall_inputInfoWallName')" v-model="wallName" />
                            </div>
                            <div v-if="!$v.wallName.required" class="invalid-feedback">
                                {{ $t("error_fieldIsRequired") }}
                            </div>
                            <div v-else-if="!$v.wallName.minLen" class="invalid-feedback">
                                {{ $t("error_minLengthMsg", [$v.wallName.$params.minLen.min]) }}
                            </div>
                            <span class="form-text text-muted">{{ $t("wall_detailInfoWallName") }}</span>
                        </div>
                        <div class="form-group validated">
                            <label >{{ $t("wall_wallType") }} *</label>
                            <select id="wallTypeTypeSelect" class="form-control kt-select2">
                                <!-- To display placeholder -->
                                <option></option>
                            </select>
                            <div v-if="!$v.wallTypeTypeSelectVal.required" class="invalid-feedback">
                                {{ $t("error_fieldIsRequired") }}
                            </div>
                            <span class="form-text text-muted">{{ $t("site_detailInfoSiteType") }}</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="wallModalCancelButton" type="button" class="btn btn-secondary" @click="cancelWall" data-dismiss="modal">{{ $t("common_cancel") }}</button>
                        <button v-if="isCreation" id="wallModalSubmitButton" type="button" @click="submitWall" data-dismiss="modal" class="btn btn-brand trigger-submit" :disabled="$v.$invalid">{{ $t("common_create") }}</button>
                        <button v-else id="wallModalSubmitButton" type="button" @click="submitWall" data-dismiss="modal" class="btn btn-brand trigger-submit" :disabled="$v.$invalid">{{ $t("common_update") }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import commonVueHelper from "../../helpers/commonVueHelper";
import i18n from "../../i18n";
export default {
    data() {
        return {
            wallName: "",
            isCreation: false,
            isEditPolygon: false,
            wallTypeTypeSelectVal: "",
            wallIsInFullScreen: false,
        };
    },
    created: function() {
        console.log("Component(wallmodal)::created() - called");
        let siteId = this.$route.params.siteId;
        this.getSiteWalls(siteId);
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentWall: function(wall) {
            console.log("Component(wallModal)::watch(currentWall) - called with : ", wall);

            this.wallIsInFullScreen = $("#map #blocWallsList").length ? true : false;
            this.initWallTypeSelect2(this.wallIsInFullScreen);

            if (wall.state == "FINALIZE_CREATION" || wall.state == "FINALIZE_EDITION") {
                this.isCreation = wall.state == "FINALIZE_CREATION" ? true : false;
                this.wallName = wall.name ? wall.name : i18n.t("wall_defaultName") + " " + (this.siteWalls.length + 1);

                if ($("#wallTypeTypeSelect").data("select2")) {
                    $("#wallTypeTypeSelect").val(wall.wallType);
                    $("#wallTypeTypeSelect").trigger({ type: "change", params: { initialInit: true } });
                }

                $("#wallModal").modal("show");
            } else {
                this.isEditPolygon = wall.state == "EDIT" ? true : false;
            }
        },

        wallTypes: function() {
            this.initWallTypeSelect2();
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentWall", "wallTypes", "siteWalls", "getWallColorById"])
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        wallName: {
            required,
            minLen: minLength(2)
        },
        wallTypeTypeSelectVal: {
            required
        }
    },
    methods: {

        ...mapActions(["createWall", "updateWall", "updateWallPolygon", "setCurrentWall", "getWallById", "getSiteWalls"]),

        // Function called to init wall types select2
        initWallTypeSelect2(wallIsInFullScreen) {
            var self = this;

            // Order wallType according to rssiAttenuation2_4Ghz
            const sortedWallTypesByAttenuation = _.sortBy(this.wallTypes, ['rssiAttenuation2_4Ghz']);

            // Create array 
            let dataArr = [];
            _.forEach(sortedWallTypesByAttenuation, wallType => {
                dataArr.push({
                    id: wallType.id, // Will be the value
                    text: i18n.t(wallType.name), // Will be the text displayed
                    html: `<span class="wallTypeSpan"><img class="wallTypeLine" style="background-color: ` + wallType.color + `!important" />` + this.$t(wallType.name) + `</span>`
                });
            });

            // Init unit Select2
            commonVueHelper.destroySelect2($("#wallTypeTypeSelect"));
            $("#wallTypeTypeSelect").select2({
                placeholder: i18n.t("wall_wallTypePlaceholder"),
                dropdownParent: wallIsInFullScreen ? $('#map') : $('body'),
                data: dataArr,
                width: "100%",
                minimumResultsForSearch: -1,
                dropdownCssClass: "wallTypeDrop",
                escapeMarkup: function(markup) {
                    return markup;
                },
                templateResult: function(data) {
                    return data.html;
                },
                templateSelection: function(data) {
                    
                    return data.html || data.text;
                }
            }).val(null).trigger("change").on("change", function() {
                self.wallTypeTypeSelectVal = $(this).val();
            });
        },

        submitWall() {
            console.log("Component(wallModal)::submitWall() - called");
            let wallData = this.currentWall;
            wallData.name = this.wallName;
            wallData.wallTypeId = this.wallTypeTypeSelectVal;
            if (this.isCreation) {
                this.createWall(wallData);
            } else if (this.isEditPolygon) {
                this.updateWallPolygon(wallData);
            } else {
                this.updateWall(wallData);
            }
            $("#wallModal").modal("hide");

            // Reset current wall in store
            this.setCurrentWall({ id: "", state: "" });
        },
        cancelWall() {
            console.log("Component(wallModal)::cancelWall() - called");
            if (this.currentWall.state === "FINALIZE_CREATION") {
                this.setCurrentWall({ id: "", state: "" });
            } else if (this.currentWall.state === "FINALIZE_EDITION") {
                this.setCurrentWall({ id: "", state: "CANCEL_EDIT" });
                // Renitialisation of tooltip
                commonVueHelper.initToolTips();
            } else {
                this.setCurrentWall({ id: "", state: "" });
            }
        },
        onSubmit() {
            console.log("Component(wallModal)::onSubmit() - called");
            if (!this.$v.$invalid) {
                this.submitWall();
            }
        }
    }
};
</script>

<style>
.wallTypeLine {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: none;
}

.wallTypeDrop .select2-results>.select2-results__options {
    max-height: 278px;
    overflow-y: auto;
}
</style>
