<template>
    <div id="wallsPanel" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="walls"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="wall_wallsListSubTitle"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <app-accesserrorportlet ref="accessErrorPortlet" v-show="!accessGranted" activeItem="walls" :siteId="siteId"></app-accesserrorportlet>

                        <div v-if="accessGranted" class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row marginRowLeftAndRightZero">
                                <div class="col-lg-12 alert alert-light alert-elevate" role="alert">
                                    <div class="alert-icon"><i class="flaticon-questions-circular-button kt-font-brand"></i></div>
                                    <div class="alert-text">
                                        <span>{{ $t("wall_helpText1") }}</span>
                                        <br />
                                        <span>{{ $t("wall_helpText2") }}</span>
                                    </div>
                                    <div class="alert-close">
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true"><i class="la la-close"></i></span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div id="rowWallsList" class="col-lg-5 col-xl-4">
                                    <!--begin::Portlet-->
                                    <div id="blocWallsList" class="kt-portlet kt-portlet--height-fluid" style="pointer-events: initial;">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("wall_wallsList") }}
                                                </h3>
                                            </div>
                                            <div v-if="currentSite" class="kt-portlet__head-toolbar">
                                                <template v-if="(currentWall.state === '' || currentWall.state === 'HIGHLIGHT') && siteWalls && siteWalls.length !== 0 && !currentSite.isInProduction">
                                                    <button id="createNewWallButton" @click="onCreate" class="btn btn-brand btn-bold btn-sm btn-icon-h">
                                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                                        <span id="createNewWallLabel">{{ $t("common_create") }}</span>
                                                    </button>
                                                </template>
                                                <button  v-if="(currentWall.state === '' || currentWall.state === 'HIGHLIGHT') && siteWalls && siteWalls.length !== 0 && currentSite.isInProduction" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled btn btn-brand btn-bold btn-sm btn-icon-h">
                                                    <i class="flaticon2-plus kt-margin-r-5"></i>
                                                    <span id="createNewWallLabel">{{ $t("common_create") }}</span>
                                                </button>
                                                <template v-if="(currentWall.state === 'START_CREATION' || currentWall.state === 'FINALIZE_CREATION' || currentWall.state === 'START_MEASURE') && siteWalls.length !== 0 && !currentSite.isInProduction">
                                                    <button id="cancelCreateNewWallButton" @click="onCancelCreation" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('gtw_cancelAction')" class="wallListLink btn btn-sm btn-outline-dark btn-bold">
                                                        <span id="cancelCreateWallLabel">{{ $t("common_cancel") }}</span>
                                                    </button>
                                                </template>
                                                <button v-if="(currentWall.state === 'START_CREATION' || currentWall.state === 'FINALIZE_CREATION' || currentWall.state === 'START_MEASURE') && siteWalls.length !== 0 && currentSite.isInProduction" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled wallListLink btn btn-sm btn-outline-dark btn-bold">
                                                    <span id="cancelCreateWallLabel">{{ $t("common_cancel") }}</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span v-if="siteWalls && siteWalls.length == 1" class="counterTopList kt-badge font500 kt-margin-b-25 kt-badge--info kt-badge--inline list-top-badge">{{ siteWalls.length }} {{ $t("wall_wall") }} &nbsp;/&nbsp; {{ totalDistance }} m</span>
                                            <span v-else-if="siteWalls && siteWalls.length > 1" class="counterTopList kt-badge font500 kt-margin-b-25 kt-badge--info kt-badge--inline list-top-badge">{{ siteWalls.length }} {{ $t("wall_walls") }} &nbsp;/&nbsp; {{ totalDistance }} m</span>

                                            <!--begin emptyList component-->
                                            <app-emptylist targetObjectName="WALL" mainTextKey="wall_notFound" subTextKey="wall_notFoundSubLabel" imgName="empty_wall.svg" v-bind:isDisplayed="isEmptyList" :siteInProduction="siteInProduction" :class="{ fullWidth: true }" @oncreatewall="onCreate" @oncancelcreatewall="onCancelCreation"></app-emptylist>
                                            <!--end emptyList component-->

                                            <div v-if="siteWalls && siteWalls.length !== 0" class="form-group">
                                                <div class="kt-input-icon kt-input-icon--left" style="margin-top: -10px; margin-bottom: -10px;">
                                                    <input v-model="searchTextValue" type="search" class="form-control" :placeholder="$t('wall_searchBy')" id="walls_searchInput" />
                                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                        <span><i class="la la-search"></i></span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="tab-content">
                                                <div class="tab-pane active">
                                                    <div id="wallsListWithScrollbar" v-if="filteredWalls.length > 0 && currentSite" class="kt-scroll" data-scroll="true" data-height="600">
                                                        <div class="kt-widget4" id="wallsList">
                                                            <!-- Items will goes there -->
                                                            <div v-for="(wall, index) in filteredWalls" :key="wall.id" class="kt-widget4__item wallLine" :class="index === filteredWalls.length - 1 ? 'kt-margin-b-30' : ''">
                                                                <div @click="onHighlight" :data-wallid="wall.id" v-bind:class="getHighlightListClass(wall)" class="kt-widget4__info">
                                                                    <a @click="onHighlight" :data-wallid="wall.id" href="#" class="highlightWallLocationLink kt-widget4__username" v-bind:class="getHighlightClass(wall)">
                                                                        {{ wall.name }}
                                                                    </a>
                                                                    <p class="kt-widget4__text colorSubText">
                                                                        {{ wall.distance }}
                                                                        <span v-if="wall.distanceUnit === 'meters'">m</span>
                                                                        <span v-else>ft</span>
                                                                    </p>
                                                                    <p class="kt-widget4__text colorSubText">{{ wall.building.name }} / {{ wall.floor.name }} ({{ $t("b&f_level") }}: {{ wall.floor.floorNumber }})</p>
                                                                </div>

                                                                <template v-if="currentWall.id === wall.id && currentWall.state == 'EDIT'">
                                                                    <button @click="onCancelEditWall" :data-wallid="wall.id" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('gtw_cancelAction')" class="cancelEditWallLocationButton wallListLink btn btn-sm btn-outline-dark btn-bold"> {{ $t("common_cancel") }} </button>
                                                                    <button @click="onSaveEditWall" :data-wallid="wall.id" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('wall_saveEditWall')" class="saveEditWallLocationButton btn btn-sm btn-outline-dark btn-bold"> <i class="la la-save editedAreaIcon"></i> {{ $t("common_save") }} </button>
                                                                </template>

                                                                <template v-if="(currentWall.id === '' || currentWall.state === 'HIGHLIGHT') && currentWall.state !== 'START_CREATION' && currentWall.state !== 'START_MEASURE'">
                                                                    <button @click="onHighlight" :data-wallid="wall.id" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('wall_highlightWall')" class="highlightWallLocationButton wallListLink btn btn-sm btn-outline-success btn-bold">
                                                                        <i class="la la-crosshairs wallListIcon"></i>
                                                                    </button>
                                                                    <div
                                                                        class="editWallDropdown btn-
                                                                    group dropleft show"
                                                                        data-toggle="kt-tooltip"
                                                                        :data-original-title="$t('common_edit')"
                                                                        data-placement="top"
                                                                    >
                                                                        <button class="editWallDropdownButton wallListLink btn btn-sm btn-outline-warning btn-bold" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                            <i class="la la-pencil wallListIcon"></i>
                                                                        </button>
                                                                        <!--<div class="dropdown-menu" x-placement="left-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-184px, 0px, 0px);">-->
                                                                        <div class="dropdown-menu">
                                                                            <button @click="onEditWallInfos" :data-wallid="wall.id" class="editWallInfosItem dropdown-item">{{ $t("wall_editInformations") }}</button>
                                                                            <button v-if="!currentSite.isInProduction" @click="onEditWall" :data-wallid="wall.id" class="editWallPolygonItem dropdown-item">{{ $t("wall_editPolyline") }}</button>
                                                                            <button v-else :data-wallid="wall.id" class="editWallPolygonItem dropdown-item" data-toggle="kt-tooltip" :data-original-title="$t('common_unauthorizedAction')" data-placement="top">
                                                                                <del> {{ $t("wall_editPolyline") }} </del>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <button v-if="!currentSite.isInProduction" @click="onDeleteWall" :data-wallid="wall.id" :data-wallname="wall.name" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('wall_removeWall')" class="removeWallButton btn btn-sm btn-outline-danger btn-bold">
                                                                        <i class="la la-remove wallListIcon"></i>
                                                                    </button>
                                                                    <button v-else data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled removeWallLocationDisabledButton removeWallButton btn btn-sm btn-outline-danger btn-bold">
                                                                        <i class="la la-remove wallListIcon"></i>
                                                                    </button>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Portlet-->
                                </div>
                                <div class="col-lg-7 col-xl-8">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet kt-portlet--tab">
                                        <div class="kt-portlet__body padding2">
                                            <div class="kt-section kt-margin-b-0">
                                                <!--begin: map -->
                                                <app-locationmap mode="wallsDefinition"></app-locationmap>
                                                <!--end: map -->
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Portlet-->
                                </div>
                                <!-- end:: Content -->
                            </div>
                        </div>
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
        <app-wallmodal></app-wallmodal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import SiteTitle from "../site/widgets/sitetitle.vue";
import LocationMap from "../map/locationmap.vue";
import WallModal from "../modals/wallmodal.vue";
import AccessErrorPortlet from "../utils/accesserrorportlet.vue";
import commonVueHelper from '../../helpers/commonVueHelper';
import L from "leaflet"; // eslint-disable-line no-unused-vars

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            siteWallsTable: null,
            isEmptyList: false,
            isCreation: true,
            localWalls: [],
            searchTextValue: "",
            totalDistance: 0,
            isMounted: false,
            siteInProduction: false, // using for emptylist element prop
        };
    },
    created: function() {
        console.log("Component(Walls)::created() - called");

        this.getSiteIndoorAreas(this.siteId);
        this.getSiteBLEGateways(this.siteId);
        this.getSiteAutoCalibrationTags(this.siteId);
        this.getSiteGeofences(this.siteId);
    },
    mounted: function() {
        console.log("Component(Walls)::mounted() - Init metronic layout");
        this.isMounted = true;
        $(window).on("resize", () => {
            this.updateScrollbar();
        });
        this.updateScrollbar();
        this.getWallTypes();
    },
    destroyed: function() {
        console.log("Component(Walls)::destroyed() - called");
        this.resetWallsState();
        this.localWalls = [];
        this.searchTextValue = "";
        this.setIndoorAreasLayerDisplayed(false);
        this.setGeofencesLayerDisplayed(false);
        this.setGatewaysLayerDisplayed(false);
        this.setAutocalibrationTagsLayerDisplayed(false);
        this.setWallsLayerDisplayed(false);
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --

        siteWalls(areas) {
            console.log("Component(Walls)::watch(siteWalls) - called with : ", areas);
            if (this.siteWalls) {
                this.localWalls = this.siteWalls;
                if (this.siteWalls.length == 0) {
                    this.isEmptyList = true;
                } else {
                    this.isEmptyList = false;
                    this.calculateTotalWalls();
                }
            }
            // Now show wall highlight
            if (this.$route.params && this.$route.params.wallId) {
                // Come from site deployment checking page to see location of wall
                // Query hightlight value is wall id
                let wallObj = this.getWallById(this.$route.params.wallId);
                if (wallObj && wallObj.id) {
                    commonVueHelper.initToolTips();
                    setTimeout(()=> {
                        this.setCurrentWall({ id: wallObj.id, state: "HIGHLIGHT" });
                    }, 10);
                }
            }
        },

        buildingsAndTheirFloorsArray: function(arrayBF) {
            console.log("Component(Walls)::watch(buildingsAndTheirFloorsArray) - called with : ", arrayBF);
            this.getSiteWalls(this.siteId);
        },

        currentWall() {
            // Scroll only if highlight comes from the click on a marker inside the map
            if (this.currentWall.state === "HIGHLIGHT" && this.currentWall.isHighlightFromMap) {
                let element = $(".highlightWallLocationLink[data-wallid='" + this.currentWall.id + "']");
                $("#wallsListWithScrollbar").scrollTop(0);
                $("#wallsListWithScrollbar").animate({scrollTop: element.offset().top - 350}, 600);
            }
        },

        currentSite(site) {
            console.log("Component(Walls)::watch(currentSite)");
            if (site) {
                this.siteInProduction = site.isInProduction;
            }
        },
        
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "siteWalls",
            "buildingsAndTheirFloorsArray",
            "getBuildingById",
            "getFloorByBuildingIdAndFloorId",
            "currentWall",
            "getWallById",
            "currentSite",
            "getWallColorById"
        ]),

        // Computed property used to filter
        filteredWalls: function() {
            // Init tooltips when render will be finished
            this.$nextTick(() => {
                this.updateScrollbar();
                $('[data-toggle="kt-tooltip"]')
                    .tooltip({ trigger: "hover" })
                    .on("click", function() {
                        $(this).tooltip("hide");
                    });
            });
            let self = this;
            if (!this.currentSite) {
                return [];
            }
            if (!self.searchTextValue) {
                return this.localWalls;
            } else {
                let filtered = this.localWalls.filter(function(wall) {
                    return wall.name.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1 || (wall.building && wall.building.name.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1) || (wall.floor && wall.floor.name.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1);
                });
                return filtered;
            }
        },

        accessGranted: function() {
            if (this.isMounted) {
                return this.$refs.accessErrorPortlet.isAccessGranted();
            } else return true; // Do not display error portlet by default
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "setCurrentWall",
            "getSiteWalls",
            "showDeleteModal",
            "resetWallsState",
            "getSiteBuildingsAndTheirFloors",
            "getWallTypes",
            "getSiteIndoorAreas",
            "getSiteBLEGateways",
            "getSiteAutoCalibrationTags",
            "getSiteGeofences",
            "setIndoorAreasLayerDisplayed",
            "setGeofencesLayerDisplayed",
            "setGatewaysLayerDisplayed",
            "setAutocalibrationTagsLayerDisplayed",
            "setWallsLayerDisplayed"
        ]),

        updateScrollbar() {
            let wallsListScrollbar = $("#wallsListWithScrollbar");
            let offset = 185;
            if (wallsListScrollbar) {
                // timeout is used to be sure to get the current map height
                setTimeout(() => {
                    let mapHeight = $("#map").height();
                    wallsListScrollbar.data("height", mapHeight > offset * 2 ? mapHeight - offset : offset);
                    wallsListScrollbar.attr("data-height", $("#map").height() - offset);
                    KTApp.init();
                    KTLayout.init();
                }, 100);
            }
        },

        // This method is called to get list of classes that should be defined to highlight element into the list
        getHighlightClass(wall) {
            return {
                cursorDefault: !wall.geometry || !wall.geometry.type,
                highlightedName: this.currentWall.id === wall.id,
                colorMainText: this.currentWall.id !== wall.id
            };
        },

        // This method is called to get list of classes that should be defined to highlight element into the list
        getHighlightListClass(wall) {
            return {
                cursorPointer: wall.geometry && wall.geometry.type,
                highlightedItemList: this.currentWall.id === wall.id
            };
        },

        onCreate() {
            // small timer is needed for autoscroll over map to work correctly
            setTimeout(() => {
                // Scroll on leaflet map
                if ($("#map").offset().top > $(window).height()) {
                    // only if map is not currently visible on screen, autoscroll
                    document.getElementById("map").scrollIntoView();
                }

                this.setCurrentWall({ id: "", state: "START_CREATION" });
            }, 1);
        },

        onCancelCreation() {
            console.log("Component(Walls)::onCancelCreate - Cancel button clicked.");
            if (this.currentWall.state === "START_MEASURE") {
                this.setCurrentWall({ id: "", state: "CANCEL_MEASURE" });
            } else {
                this.setCurrentWall({ id: "", state: "CANCEL_CREATION" });
            }
            // Renitialisation of tooltip
            commonVueHelper.initToolTips();
        },

        onHighlight(event) {
            let target = event.currentTarget;
            let wallId = $(target).attr("data-wallid");
            console.log("Component(Walls)::onHighlight - Highlight button clicked. wallId = " + wallId);
            this.setCurrentWall({ id: wallId, state: "HIGHLIGHT" });
        },

        onEditWall(event) {
            let target = event.currentTarget;
            let wallId = $(target).attr("data-wallid");
            console.log("Component(Walls)::onEditWall - Edit polygon clicked. wallId = " + wallId);
            this.setCurrentWall({ id: wallId, state: "EDIT" });
        },

        onEditWallInfos(event) {
            let target = event.currentTarget;
            let wallId = $(target).attr("data-wallid");
            console.log("Component(Walls)::onEditWallInfos - Edit infos clicked. wallId = " + wallId);

            // Get last version of wall
            let wall = this.getWallById(wallId);

            let wallData = {
                id: wall.id,
                name: wall.name,
                siteId: wall.site,
                buildingId: wall.building.id,
                floorId: wall.floor.id,
                geometry: wall.geometry,
                distance: wall.distance,
                distanceUnit: wall.distanceUnit,
                state: "FINALIZE_EDITION",
                wallType: wall.wallType
            };
            // Will open confirmation pop-up from wallModal.vue component
            this.setCurrentWall(wallData);
        },

        // This method is called when user clicks on "Cancel" button after edit one wall
        onCancelEditWall(event) {
            let target = event.currentTarget;
            let wallId = $(target).attr("data-wallid");
            console.log("Component(Walls)::onCancelEditWall - wallId = " + wallId);
            this.setCurrentWall({ id: wallId, state: "CANCEL_EDIT" });
            // Renitialisation of tooltip
            commonVueHelper.initToolTips();
        },

        // This method is called when user click ton "Save" button after edit location of one gateway
        onSaveEditWall(event) {
            let target = event.currentTarget;
            let wallId = $(target).attr("data-wallid");
            console.log("Component(Walls)::onSaveEditWall - wallId = " + wallId);
            this.setCurrentWall({ id: wallId, state: "SAVE_EDIT" });
        },

        onDeleteWall(event) {
            console.log("Component(Walls)::onDeleteWall - Delete button clicked. wallId = " + wallId);
            let target = event.currentTarget;
            let wallId = $(target).attr("data-wallid");
            let wallName = $(target).attr("data-wallname");
            this.showDeleteModal({ textContent: i18n.t("wall_deleteWallConfirmText", { wallName: wallName }), actionName: "DELETE_WALL", params: { siteId: this.siteId, wallId: wallId } });
        },

        calculateTotalWalls() {
            console.log("Component(Walls)::calculateTotalWalls - called ");
            let totalDistance = 0;
            for (let wall of this.siteWalls) {
                totalDistance += wall.distance;
            }
            this.totalDistance = Math.round(totalDistance * 1000) / 1000;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle,
        "app-locationmap": LocationMap,
        "app-wallmodal": WallModal,
        "app-accesserrorportlet": AccessErrorPortlet
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.padding2 {
    padding: 2px;
    z-index: 1;
}
.wallListIcon {
    margin-top: -2px;
    margin-right: -11px;
    margin-left: -6px;
}
.wallListLink {
    margin-right: 3px;
}
.alreadyLocatedLink {
    margin-right: 3px;
}
.editedAreaIcon {
    margin-top: -4px;
}
.colorMainText {
    color: #5c7cf7 !important;
}
.colorSubText {
    color: #5a6077 !important;
}
.cursorPointer {
    cursor: pointer;
}
.font500 {
    font-weight: 500;
}
.list-top-badge {
    margin-top: -10px;
}
.counterTopList {
    font-size: 12px;
    padding: 10px 10px 10px 10px;
    width: fit-content;
    background: #009688 !important;
}
.wallLine a:last-child {
    margin-right: 8px;
}

.helpButtonContainer {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.removeWallLocationDisabledButton {
    margin-right: 8px;
}
</style>

<style>
.leaflet-tooltip-left:before {
    display: none;
}
</style>
